import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TableLoader from '../Admin/TableLoader';
import { AiOutlineSearch } from 'react-icons/ai';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { saveAs } from 'file-saver';

const CustomerTable = () => {
  const { loading, users } = useSelector((state) => state.allusers);
  const [items, setItems] = useState(users);
  const [displayCount, setDisplayCount] = useState(10); // Number of items to show initially
  const [searchValue, setSearchValue] = useState('');
  const [startDeliveryDate, setStartDeliveryDate] = useState(null);
  const [endDeliveryDate, setEndDeliveryDate] = useState(null);

  useEffect(() => {
    setItems(users);
  }, [users]);

  const searchFilter = (item) => {
    const name = `${item.firstName} ${item.lastName}`.toLowerCase();
    const email = item.emailId.toLowerCase();
    const createdDate = moment(item.createdDate).format('YYYY-MM-DD');
    const isWithinDateRange =
      (!startDeliveryDate || moment(createdDate).isSameOrAfter(startDeliveryDate)) &&
      (!endDeliveryDate || moment(createdDate).isSameOrBefore(endDeliveryDate));
    return (name.includes(searchValue) || email.includes(searchValue)) && isWithinDateRange;
  };

  const SearchHandleChange = (e) => {
    const searchInput = e.target.value.toLowerCase();
    setSearchValue(searchInput);
    const filteredItems = users.filter(searchFilter);
    setItems(filteredItems);
    setDisplayCount(10); // Reset display count when filtering
  };

  const handleDateChange = ([start, end]) => {
    setStartDeliveryDate(start);
    setEndDeliveryDate(end);
    const filteredItems = users.filter(searchFilter);
    setItems(filteredItems);
    setDisplayCount(10); // Reset display count when filtering
  };

  const loadMore = () => {
    setDisplayCount((prevCount) => prevCount + 10); // Load 10 more items
  };
  const downloadCSV = () => {
    const csvHeader = 'S.No.,Customer Name,Customer Email,Marketing Consent,Consent,Created Date\n';
    const csvRows = items.map((item, i) => {
      const formattedDate = moment(item.createdDate).format('YYYY-MM-DD'); // Convert timestamp to readable date
      return `${i + 1},${item.firstName} ${item.lastName},${item.emailId},${
        item.marketingConsent ? 'Yes' : 'No'
      },${item.consent ? 'Yes' : 'No'},"\t${formattedDate}"`;
    });
    const csvData = [csvHeader, ...csvRows].join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'customer_report.csv');
  };
  const resetFilters = () => {
    setSearchValue('');
    setStartDeliveryDate(null);
    setEndDeliveryDate(null);
    setItems(users); // Reset items to original data
    setDisplayCount(10); // Reset display count
  };
  return (
    <Fragment>
      <div className="pb-4 md:m-0 py-4">
        {loading ? (
          <TableLoader />
        ) : (
          <Fragment>
            <div className="breadcrumb flex items-center justify-between mb-4">
              <div className="grid grid-cols-3 gap-4 w-full">
                <div className="relative">
                  <input
                    type="text"
                    className="block p-2.5 w-full text-sm bg-gray-50 rounded-lg border border-gray-500 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Search by Name / Email"
                    onChange={SearchHandleChange}
                    autoComplete="off"
                  />
                  <button
                    type="submit"
                    className="absolute top-0 right-0 p-2.5 text-sm text-white bg-gray-600 rounded-r-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300"
                  >
                    <AiOutlineSearch size="20" />
                  </button>
                </div>
                <ReactDatePicker
                  selectsRange
                  startDate={startDeliveryDate}
                  endDate={endDeliveryDate}
                  onChange={handleDateChange}
                  isClearable
                  placeholderText="Select Date Range"
                  className="p-2.5 text-sm bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-full"
                />
                  {(searchValue || startDeliveryDate || endDeliveryDate) && (
                  <button
                    onClick={resetFilters}
                    className="px-5 py-3 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 w-56"
                  >
                    Reset Filters
                  </button>
                )}
              </div>
              <button
                onClick={downloadCSV}
                className="px-5 py-3 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 w-56"
              >
                Download Report
              </button>
            </div>
            <div className="w-full overflow-hidden rounded-lg shadow-md border-2 border-gray-300">
              <div className="w-full overflow-x-auto">
                <table className="w-full whitespace-no-wrap">
                  <thead className="text-xs font-semibold tracking-wide text-left text-gray-500 capitalize border-b bg-gray-50">
                    <tr>
                      <td className="border tracking-widest px-4 py-3">S.No.</td>
                      <td className="border tracking-widest px-4 py-3">Customer Name</td>
                      <td className="border tracking-widest px-4 py-3">Customer Email</td>
                      <td className="border tracking-widest px-4 py-3">Marketing Consent</td>
                      <td className="border tracking-widest px-4 py-3">Consent</td>
                      <td className="border tracking-widest px-4 py-3">Created Date</td>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y text-gray-700">
                    {items.slice(0, displayCount).map((item, key) => (
                      <tr key={key}>
                        <td className="border px-4 py-3"><p className="text-sm font-semibold text-black">{key + 1}</p></td>
                        <td className="border px-4 py-3"><p className="text-sm font-semibold text-black">{`${item.firstName} ${item.lastName}`}</p></td>
                        <td className="border px-4 py-3"><p className="text-sm font-semibold text-black">{item.emailId}</p></td>
                        <td className="border px-4 py-3"><p className="text-sm font-semibold text-black">{item.marketingConsent ? 'Yes' : 'No'}</p></td>
                        <td className="border px-4 py-3"><p className="text-sm font-semibold text-black">{item.consent ? 'Yes' : 'No'}</p></td>
                        <td className="border px-4 py-3">
                        <p className="text-sm font-semibold text-black">
                          {moment(item.createdDate).format('YYYY-MM-DD')}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {displayCount < items.length && (
                <div className="flex justify-center my-3">
                  <button
                    onClick={loadMore}
                    className="px-5 py-3 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 mx-auto"
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default CustomerTable;
